import { LitElementAttribute } from "./fable_modules/Fable.Lit.1.6.2-oceanbox/LitElement.fs.js";
import { Hook_get_emptyDisposable, Hook_getContext_343DAFF1 } from "./fable_modules/Fable.Lit.1.6.2-oceanbox/Hook.fs.js";
import { startImmediate } from "./fable_modules/fable-library-js.4.24.0/Async.js";
import { singleton } from "./fable_modules/fable-library-js.4.24.0/AsyncBuilder.js";
import { authApi } from "./Lib/Remoting.jsx";
import { initAtlantisSessionUrls } from "./Lib/Utils.jsx";
import { Lit_get_nothing } from "./fable_modules/Fable.Lit.1.6.2-oceanbox/Lit.fs.js";

export const InitApp = (new LitElementAttribute("init-app")).Decorate(function () {
    (this).init((arg) => {
        let a;
        a = (arg.useShadowDom = false);
        return Promise.resolve(undefined);
    });
    Hook_getContext_343DAFF1(this).useEffectOnce(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(authApi.IsAuthenticated(), (_arg) => singleton.Combine(((_arg != null) && !((sessionStorage["archive_id"]) == null)) ? singleton.Bind(initAtlantisSessionUrls(), () => {
            window.location.href = "/map.html";
            return singleton.Zero();
        }) : ((sessionStorage.removeItem("archive_id"), (window.location.href = "/atlas.html", singleton.Zero()))), singleton.Delay(() => singleton.Return(undefined))))));
        return Hook_get_emptyDisposable();
    });
    return Lit_get_nothing();
});

