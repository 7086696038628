import { toString, Record, Union } from "../fable-library-js.4.24.0/Types.js";
import { list_type, bool_type, option_type, class_type, float32_type, string_type, int32_type, record_type, tuple_type, float64_type, union_type } from "../fable-library-js.4.24.0/Reflection.js";
import { op_UnaryNegation_Int32 } from "../fable-library-js.4.24.0/Int32.js";
import { now } from "../fable-library-js.4.24.0/Date.js";
import { mapIndexed, singleton, append, ofSeq as ofSeq_2, filter, length, choose, map as map_1, empty } from "../fable-library-js.4.24.0/List.js";
import { toList as toList_1, map as map_2, values, ofList, ofSeq, empty as empty_1 } from "../fable-library-js.4.24.0/Map.js";
import { comparePrimitives, compare } from "../fable-library-js.4.24.0/Util.js";
import { map, defaultArg } from "../fable-library-js.4.24.0/Option.js";
import { toList } from "../fable-library-js.4.24.0/Seq.js";
import { ofSeq as ofSeq_1, empty as empty_2 } from "../fable-library-js.4.24.0/Set.js";

export class SimType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LiceSim", "VirusSim", "TransportSim", "DepositionSim", "DownwellingSim", "WaterContactSim"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "virus" : ((x.tag === 2) ? "transport" : ((x.tag === 3) ? "sedimentation" : ((x.tag === 4) ? "downwelling" : ((x.tag === 5) ? "watercontact" : "lice"))));
    }
}

export function SimType_$reflection() {
    return union_type("Drifters.ApiTypes.SimType", [], SimType, () => [[], [], [], [], [], []]);
}

export function SimType__ToLabel(x) {
    switch (x.tag) {
        case 1:
            return "Virus";
        case 2:
            return "Transport";
        case 3:
            return "Deposition";
        case 4:
            return "Downwelling";
        case 5:
            return "Water Contact";
        default:
            return "Lice";
    }
}

export class AnalysisType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ConnectionAnalysis", "ConcentrationAnalysis", "SedimentationAnalysis", "AccumulationAnalysis", "WaterContactAnalysis", "DownwellingAnalysis"];
    }
    toString() {
        const x = this;
        return (x.tag === 0) ? "connection" : ((x.tag === 1) ? "concentration" : ((x.tag === 2) ? "sedimentation" : ((x.tag === 4) ? "watercontact" : ((x.tag === 5) ? "downwelling" : "accumulation"))));
    }
}

export function AnalysisType_$reflection() {
    return union_type("Drifters.ApiTypes.AnalysisType", [], AnalysisType, () => [[], [], [], [], [], []]);
}

export class IntegrationMethod extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RungeKutta3D", "Euler3D"];
    }
    toString() {
        const x = this;
        return (x.tag === 0) ? "runge_kutta_3d" : "euler_3d";
    }
}

export function IntegrationMethod_$reflection() {
    return union_type("Drifters.ApiTypes.IntegrationMethod", [], IntegrationMethod, () => [[], []]);
}

export function IntegrationMethod_FromString_Z721C83C5(s) {
    const matchValue = s.toLocaleLowerCase();
    switch (matchValue) {
        case "euler_3d":
            return new IntegrationMethod(1, []);
        case "runge_kutta_3d":
            return new IntegrationMethod(0, []);
        default:
            throw new Error(`Invalid integration method : ${s}`);
    }
}

export class InterpolationMethod extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["CloughTocherC1", "LinearCentroid", "ConstantCentroid"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "linear_centroid" : ((x.tag === 2) ? "constant_centroid" : "clough_tocher_c1");
    }
}

export function InterpolationMethod_$reflection() {
    return union_type("Drifters.ApiTypes.InterpolationMethod", [], InterpolationMethod, () => [[], [], []]);
}

export function InterpolationMethod_FromString_Z721C83C5(s) {
    const matchValue = s.toLocaleLowerCase();
    switch (matchValue) {
        case "clough_tocher_c1":
            return new InterpolationMethod(0, []);
        case "linear_centroid":
            return new InterpolationMethod(1, []);
        case "constant_centroid":
            return new InterpolationMethod(2, []);
        default:
            throw new Error(`Invalid interpolation method : ${s}`);
    }
}

export class BoundaryAction extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ReduceStep", "SkipStep", "Strand", "Remove"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "skip_step" : ((x.tag === 2) ? "strand" : ((x.tag === 3) ? "remove" : "reduce_step"));
    }
}

export function BoundaryAction_$reflection() {
    return union_type("Drifters.ApiTypes.BoundaryAction", [], BoundaryAction, () => [[], [], [], []]);
}

export function BoundaryAction_FromString_Z721C83C5(s) {
    const matchValue = s.toLocaleLowerCase();
    switch (matchValue) {
        case "reduce_step":
            return new BoundaryAction(0, []);
        case "skip_step":
            return new BoundaryAction(1, []);
        case "strand":
            return new BoundaryAction(2, []);
        case "remove":
            return new BoundaryAction(3, []);
        default:
            throw new Error(`Invalid boundary action : ${s}`);
    }
}

export class ShearValue extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Default", "Mud", "Sand", "Rock", "Custom"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "mud" : ((x.tag === 2) ? "sand" : ((x.tag === 3) ? "rock" : ((x.tag === 4) ? "custom" : "default")));
    }
}

export function ShearValue_$reflection() {
    return union_type("Drifters.ApiTypes.ShearValue", [], ShearValue, () => [[], [], [], [], [["Item", float64_type]]]);
}

export function ShearValue__ToFloat(x) {
    switch (x.tag) {
        case 1:
            return 0.065;
        case 2:
            return 0.12;
        case 3:
            return 0.32;
        case 4:
            return x.fields[0];
        default:
            return 0.018;
    }
}

export class GridResolution extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Low", "Medium", "High"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "medium" : ((x.tag === 2) ? "high" : "low");
    }
}

export function GridResolution_$reflection() {
    return union_type("Drifters.ApiTypes.GridResolution", [], GridResolution, () => [[], [], []]);
}

export function GridResolution__ToFileName(x) {
    switch (x.tag) {
        case 1:
            return "/data/archives/custom-grids/circle_50k.dat";
        case 2:
            return "/data/archives/custom-grids/circle_100k.dat";
        default:
            return "/data/archives/custom-grids/circle_25k.dat";
    }
}

export class CircleGrid extends Record {
    constructor(resolution, position, radius) {
        super();
        this.resolution = resolution;
        this.position = position;
        this.radius = radius;
    }
}

export function CircleGrid_$reflection() {
    return record_type("Drifters.ApiTypes.CircleGrid", [], CircleGrid, () => [["resolution", GridResolution_$reflection()], ["position", tuple_type(float64_type, float64_type)], ["radius", float64_type]]);
}

export function CircleGrid_get_unit() {
    return new CircleGrid(new GridResolution(0, []), [0, 0], 5);
}

export class ParticleResolution extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Low", "Medium", "High", "UltraHigh"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "medium" : ((x.tag === 2) ? "high" : ((x.tag === 3) ? "uhigh" : "low"));
    }
}

export function ParticleResolution_$reflection() {
    return union_type("Drifters.ApiTypes.ParticleResolution", [], ParticleResolution, () => [[], [], [], []]);
}

export function ParticleResolution__ToLabel(x) {
    switch (x.tag) {
        case 1:
            return "Medium";
        case 2:
            return "High";
        case 3:
            return "Ultra high";
        default:
            return "Low";
    }
}

export function ParticleResolution__ToSingle(x) {
    switch (x.tag) {
        case 1:
            return 3000;
        case 2:
            return 10000;
        case 3:
            return 30000;
        default:
            return 1000;
    }
}

export function ParticleResolution__ToContinuous(x) {
    switch (x.tag) {
        case 1:
            return 300;
        case 2:
            return 1000;
        case 3:
            return 3000;
        default:
            return 100;
    }
}

export class SaveFrequency extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SaveHourly", "SaveDaily", "SaveWeekly"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "save_daily" : ((x.tag === 2) ? "save_weekly" : "save_hourly");
    }
}

export function SaveFrequency_$reflection() {
    return union_type("Drifters.ApiTypes.SaveFrequency", [], SaveFrequency, () => [[], [], []]);
}

export function SaveFrequency__ToInt(x) {
    switch (x.tag) {
        case 1:
            return 86400;
        case 2:
            return 604800;
        default:
            return 3600;
    }
}

export function SaveFrequency__ToFloat(x) {
    switch (x.tag) {
        case 1:
            return 86400;
        case 2:
            return 604800;
        default:
            return 3600;
    }
}

export function SaveFrequency_FromFloat_5E38073B(freq) {
    if (freq < SaveFrequency__ToFloat(new SaveFrequency(1, []))) {
        return new SaveFrequency(0, []);
    }
    else if (freq < SaveFrequency__ToFloat(new SaveFrequency(2, []))) {
        return new SaveFrequency(1, []);
    }
    else {
        return new SaveFrequency(2, []);
    }
}

export class ReleaseFrequency extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ReleaseInstantly", "ReleaseHourly", "ReleaseDaily", "ReleaseWeekly"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "release_hourly" : ((x.tag === 2) ? "release_daily" : ((x.tag === 3) ? "release_weekly" : "release_instantly"));
    }
}

export function ReleaseFrequency_$reflection() {
    return union_type("Drifters.ApiTypes.ReleaseFrequency", [], ReleaseFrequency, () => [[], [], [], []]);
}

export function ReleaseFrequency__ToFloat(x) {
    switch (x.tag) {
        case 1:
            return 1;
        case 2:
            return 24;
        case 3:
            return 168;
        default:
            return 0;
    }
}

export function ReleaseFrequency_FromFloat_5E38073B(freq) {
    if (freq < ReleaseFrequency__ToFloat(new ReleaseFrequency(1, []))) {
        return new ReleaseFrequency(0, []);
    }
    else if (freq < ReleaseFrequency__ToFloat(new ReleaseFrequency(2, []))) {
        return new ReleaseFrequency(1, []);
    }
    else if (freq < ReleaseFrequency__ToFloat(new ReleaseFrequency(3, []))) {
        return new ReleaseFrequency(2, []);
    }
    else {
        return new ReleaseFrequency(3, []);
    }
}

export class SpatialSmoothing extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["VeryLow", "Low", "Medium", "High", "VeryHigh"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "low" : ((x.tag === 2) ? "medium" : ((x.tag === 3) ? "high" : ((x.tag === 4) ? "veryhigh" : "verylow")));
    }
}

export function SpatialSmoothing_$reflection() {
    return union_type("Drifters.ApiTypes.SpatialSmoothing", [], SpatialSmoothing, () => [[], [], [], [], []]);
}

export function SpatialSmoothing__ToRadius(x) {
    switch (x.tag) {
        case 1:
            return 64;
        case 2:
            return 128;
        case 3:
            return 256;
        case 4:
            return 512;
        default:
            return 32;
    }
}

export function SpatialSmoothing_FromString_Z721C83C5(s) {
    const matchValue = s.toLocaleLowerCase();
    switch (matchValue) {
        case "verylow":
            return new SpatialSmoothing(0, []);
        case "low":
            return new SpatialSmoothing(1, []);
        case "medium":
            return new SpatialSmoothing(2, []);
        case "high":
            return new SpatialSmoothing(3, []);
        case "veryhigh":
            return new SpatialSmoothing(4, []);
        default:
            throw new Error(`invalid smoothing option : ${s}`);
    }
}

function calcAlpha(n) {
    return 1 - Math.exp(Math.log(0.1) / n);
}

export class TemporalSmoothing extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Tidally", "Daily", "Weekly", "Monthly", "Quarterly"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "daily" : ((x.tag === 2) ? "weekly" : ((x.tag === 3) ? "monthly" : ((x.tag === 4) ? "quarterly" : "tidally")));
    }
}

export function TemporalSmoothing_$reflection() {
    return union_type("Drifters.ApiTypes.TemporalSmoothing", [], TemporalSmoothing, () => [[], [], [], [], []]);
}

export function TemporalSmoothing_FromString_Z721C83C5(s) {
    const matchValue = s.toLocaleLowerCase();
    switch (matchValue) {
        case "tidally":
            return new TemporalSmoothing(0, []);
        case "daily":
            return new TemporalSmoothing(1, []);
        case "weekly":
            return new TemporalSmoothing(2, []);
        case "monthly":
            return new TemporalSmoothing(3, []);
        case "quarterly":
            return new TemporalSmoothing(4, []);
        default:
            throw new Error(`invalid smoothing : ${s}`);
    }
}

export function TemporalSmoothing__ToLabel(x) {
    switch (x.tag) {
        case 1:
            return "Daily (24 hours)";
        case 2:
            return "Weekly (7 days)";
        case 3:
            return "Monthly (4 weeks)";
        case 4:
            return "Quarterly (3 months)";
        default:
            return "Tidally (6 hours)";
    }
}

export function TemporalSmoothing__ToHourlyFloat(x) {
    switch (x.tag) {
        case 1:
            return calcAlpha(24);
        case 2:
            return calcAlpha(168);
        case 3:
            return calcAlpha(672);
        case 4:
            return calcAlpha(2016);
        default:
            return calcAlpha(6);
    }
}

export function TemporalSmoothing__ToDailyFloat(x) {
    switch (x.tag) {
        case 1:
            return 1;
        case 2:
            return calcAlpha(7);
        case 3:
            return calcAlpha(28);
        case 4:
            return calcAlpha(84);
        default:
            return 1;
    }
}

export function TemporalSmoothing__ToWeeklyFloat(x) {
    switch (x.tag) {
        case 1:
            return 1;
        case 2:
            return 1;
        case 3:
            return calcAlpha(4);
        case 4:
            return calcAlpha(12);
        default:
            return 1;
    }
}

export class FieldKind extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Instant", "Average", "Accumulated", "Smoothed", "UndefinedField"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "average" : ((x.tag === 2) ? "accumulated" : ((x.tag === 3) ? toString(x.fields[0]) : ((x.tag === 4) ? "undefined" : "instant")));
    }
}

export function FieldKind_$reflection() {
    return union_type("Drifters.ApiTypes.FieldKind", [], FieldKind, () => [[], [], [], [["Item", TemporalSmoothing_$reflection()]], []]);
}

export function FieldKind_FromString_Z721C83C5(s) {
    const matchValue = s.toLocaleLowerCase();
    switch (matchValue) {
        case "instant":
            return new FieldKind(0, []);
        case "average":
            return new FieldKind(1, []);
        case "accumulated":
            return new FieldKind(2, []);
        case "tidally":
            return new FieldKind(3, [new TemporalSmoothing(0, [])]);
        case "daily":
            return new FieldKind(3, [new TemporalSmoothing(1, [])]);
        case "weekly":
            return new FieldKind(3, [new TemporalSmoothing(2, [])]);
        case "monthly":
            return new FieldKind(3, [new TemporalSmoothing(3, [])]);
        case "quarterly":
            return new FieldKind(3, [new TemporalSmoothing(4, [])]);
        default:
            return new FieldKind(4, []);
    }
}

export function FieldKind__ToLabel(x) {
    switch (x.tag) {
        case 1:
            return "Average";
        case 2:
            return "Accumulated";
        case 3:
            return `Smoothed: ${TemporalSmoothing__ToLabel(x.fields[0])}`;
        case 4:
            return "None";
        default:
            return "Instant";
    }
}

export class SedimentKind extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["TPM", "POC", "PON", "POP"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "poc" : ((x.tag === 2) ? "pon" : ((x.tag === 3) ? "pop" : "tpm"));
    }
}

export function SedimentKind_$reflection() {
    return union_type("Drifters.ApiTypes.SedimentKind", [], SedimentKind, () => [[], [], [], []]);
}

export function SedimentKind_FromString_Z721C83C5(s) {
    const matchValue = s.toLocaleLowerCase();
    switch (matchValue) {
        case "tpm":
            return new SedimentKind(0, []);
        case "poc":
            return new SedimentKind(1, []);
        case "pon":
            return new SedimentKind(2, []);
        case "pop":
            return new SedimentKind(3, []);
        default:
            throw new Error(`invalid sediment type : ${s}`);
    }
}

export function SedimentKind__ToLabel(x) {
    switch (x.tag) {
        case 1:
            return "Particulate Organic Carbon";
        case 2:
            return "Particulate Organic Nitrogen";
        case 3:
            return "Particulate Organic Phosphorus";
        default:
            return "Total Particulate Matter";
    }
}

export function SedimentKind__ToUnit(x) {
    switch (x.tag) {
        case 1:
            return "gC/m2";
        case 2:
            return "gN/m2";
        case 3:
            return "gP/m2";
        default:
            return "g/m2";
    }
}

export class GroupKind extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AnyGroup", "Group"];
    }
}

export function GroupKind_$reflection() {
    return union_type("Drifters.ApiTypes.GroupKind", [], GroupKind, () => [[], [["Item", int32_type]]]);
}

export function GroupKind__ToInt(x) {
    if (x.tag === 1) {
        return x.fields[0] | 0;
    }
    else {
        return -1;
    }
}

export function GroupKind_FromInt_Z524259A4(i) {
    if (i < 0) {
        return new GroupKind(0, []);
    }
    else {
        return new GroupKind(1, [i]);
    }
}

export class GroupType extends Record {
    constructor(idx, kind, name) {
        super();
        this.idx = (idx | 0);
        this.kind = kind;
        this.name = name;
    }
}

export function GroupType_$reflection() {
    return record_type("Drifters.ApiTypes.GroupType", [], GroupType, () => [["idx", int32_type], ["kind", GroupKind_$reflection()], ["name", string_type]]);
}

export class DepthKind extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Surface", "Bottom", "Depth"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "Bottom" : ((x.tag === 2) ? (`${x.fields[0]}m`) : "Surface");
    }
}

export function DepthKind_$reflection() {
    return union_type("Drifters.ApiTypes.DepthKind", [], DepthKind, () => [[], [], [["Item", int32_type]]]);
}

export function DepthKind__ToFloat(x) {
    switch (x.tag) {
        case 1:
            return -1000000;
        case 2:
            return op_UnaryNegation_Int32(x.fields[0]);
        default:
            return 0;
    }
}

export function DepthKind__ToInt(x) {
    switch (x.tag) {
        case 1:
            return -1;
        case 2:
            return x.fields[0] | 0;
        default:
            return 0;
    }
}

export function DepthKind_FromInt_Z524259A4(i) {
    if (i === 0) {
        return new DepthKind(0, []);
    }
    else if (i < 0) {
        return new DepthKind(1, []);
    }
    else {
        return new DepthKind(2, [i]);
    }
}

export class LayerType extends Record {
    constructor(idx, min, max) {
        super();
        this.idx = (idx | 0);
        this.min = min;
        this.max = max;
    }
}

export function LayerType_$reflection() {
    return record_type("Drifters.ApiTypes.LayerType", [], LayerType, () => [["idx", int32_type], ["min", DepthKind_$reflection()], ["max", DepthKind_$reflection()]]);
}

export class ParticleKind extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AnyParticle", "Particle", "Virus", "Lice", "Silt"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? (`particle-${x.fields[0]}`) : ((x.tag === 2) ? (`virus-${x.fields[0]}`) : ((x.tag === 3) ? (`lice-${x.fields[0]}`) : ((x.tag === 4) ? (`silt-${x.fields[0]}`) : "anyparticle")));
    }
}

export function ParticleKind_$reflection() {
    return union_type("Drifters.ApiTypes.ParticleKind", [], ParticleKind, () => [[], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]]]);
}

export function ParticleKind__ToInt(x) {
    switch (x.tag) {
        case 1:
            return (0 + x.fields[0]) | 0;
        case 2:
            return (100 + x.fields[0]) | 0;
        case 3:
            return (200 + x.fields[0]) | 0;
        case 4:
            return (300 + x.fields[0]) | 0;
        default:
            return -1;
    }
}

export function ParticleKind_FromInt_Z524259A4(i) {
    if (i < 0) {
        return new ParticleKind(0, []);
    }
    else if (i < 100) {
        return new ParticleKind(1, [i]);
    }
    else if (i < 200) {
        return new ParticleKind(2, [i - 100]);
    }
    else if (i < 300) {
        return new ParticleKind(3, [i - 200]);
    }
    else if (i < 400) {
        return new ParticleKind(4, [i - 300]);
    }
    else {
        throw new Error(`invalid particle kind : ${i}`);
    }
}

export class ParticleType extends Record {
    constructor(idx, kind, name) {
        super();
        this.idx = (idx | 0);
        this.kind = kind;
        this.name = name;
    }
}

export function ParticleType_$reflection() {
    return record_type("Drifters.ApiTypes.ParticleType", [], ParticleType, () => [["idx", int32_type], ["kind", ParticleKind_$reflection()], ["name", string_type]]);
}

export class ParticleState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AnyState", "Unborn", "Stranded", "Removed", "Expired", "Suspended", "Deposited", "Resuspended", "Redeposited", "Sedimented"];
    }
    toString() {
        const x = this;
        return (x.tag === 2) ? "stranded" : ((x.tag === 3) ? "removed" : ((x.tag === 4) ? "expired" : ((x.tag === 5) ? "suspended" : ((x.tag === 6) ? "deposited" : ((x.tag === 7) ? "resuspended" : ((x.tag === 8) ? "redeposited" : ((x.tag === 9) ? "sedimented" : ((x.tag === 0) ? "anystate" : "unborn"))))))));
    }
}

export function ParticleState_$reflection() {
    return union_type("Drifters.ApiTypes.ParticleState", [], ParticleState, () => [[], [], [], [], [], [], [], [], [], []]);
}

export function ParticleState_FromString_Z721C83C5(s) {
    const matchValue = s.toLocaleLowerCase();
    switch (matchValue) {
        case "unborn":
            return new ParticleState(1, []);
        case "stranded":
            return new ParticleState(2, []);
        case "removed":
            return new ParticleState(3, []);
        case "expired":
            return new ParticleState(4, []);
        case "suspended":
            return new ParticleState(5, []);
        case "deposited":
            return new ParticleState(6, []);
        case "resuspended":
            return new ParticleState(7, []);
        case "redeposited":
            return new ParticleState(8, []);
        case "sedimented":
            return new ParticleState(9, []);
        case "anystate":
            return new ParticleState(0, []);
        default:
            throw new Error(`invalid particle state : ${s}`);
    }
}

export function ParticleState__ToInt(x) {
    switch (x.tag) {
        case 5:
            return 1;
        case 2:
            return 2;
        case 3:
            return 3;
        case 6:
            return 4;
        case 9:
            return 5;
        case 7:
            return 6;
        case 8:
            return 7;
        case 4:
            return 8;
        case 0:
            return -1;
        default:
            return 0;
    }
}

export function ParticleState_FromInt_Z524259A4(i) {
    switch (i) {
        case -1:
            return new ParticleState(0, []);
        case 0:
            return new ParticleState(1, []);
        case 1:
            return new ParticleState(5, []);
        case 2:
            return new ParticleState(2, []);
        case 3:
            return new ParticleState(3, []);
        case 4:
            return new ParticleState(6, []);
        case 5:
            return new ParticleState(9, []);
        case 6:
            return new ParticleState(7, []);
        case 7:
            return new ParticleState(8, []);
        case 8:
            return new ParticleState(4, []);
        default:
            throw new Error(`invalid particle state : ${i}`);
    }
}

export class ApiParticle extends Record {
    constructor(id, age, pos, kind, group, state) {
        super();
        this.id = (id | 0);
        this.age = age;
        this.pos = pos;
        this.kind = kind;
        this.group = group;
        this.state = state;
    }
}

export function ApiParticle_$reflection() {
    return record_type("Drifters.ApiTypes.ApiParticle", [], ApiParticle, () => [["id", int32_type], ["age", float64_type], ["pos", tuple_type(float32_type, float32_type, float32_type)], ["kind", ParticleKind_$reflection()], ["group", GroupKind_$reflection()], ["state", ParticleState_$reflection()]]);
}

export class SimulationInput extends Record {
    constructor(archiveId, startTime, duration, saveFreq, reverse, projection, maxParticles, cacheBlockSize) {
        super();
        this.archiveId = archiveId;
        this.startTime = startTime;
        this.duration = duration;
        this.saveFreq = saveFreq;
        this.reverse = reverse;
        this.projection = projection;
        this.maxParticles = maxParticles;
        this.cacheBlockSize = cacheBlockSize;
    }
}

export function SimulationInput_$reflection() {
    return record_type("Drifters.ApiTypes.SimulationInput", [], SimulationInput, () => [["archiveId", class_type("System.Guid")], ["startTime", option_type(class_type("System.DateTime"))], ["duration", float64_type], ["saveFreq", option_type(float64_type)], ["reverse", option_type(bool_type)], ["projection", option_type(string_type)], ["maxParticles", option_type(int32_type)], ["cacheBlockSize", option_type(int32_type)]]);
}

export class SimulationModel extends Record {
    constructor(aid, kind, name, startTime, simDays, saveFreq, reverse) {
        super();
        this.aid = aid;
        this.kind = kind;
        this.name = name;
        this.startTime = startTime;
        this.simDays = simDays;
        this.saveFreq = saveFreq;
        this.reverse = reverse;
    }
}

export function SimulationModel_$reflection() {
    return record_type("Drifters.ApiTypes.SimulationModel", [], SimulationModel, () => [["aid", class_type("System.Guid")], ["kind", SimType_$reflection()], ["name", string_type], ["startTime", class_type("System.DateTime")], ["simDays", float64_type], ["saveFreq", SaveFrequency_$reflection()], ["reverse", bool_type]]);
}

export function SimulationModel_empty_Z160CC370(kind) {
    return new SimulationModel("00000000-0000-0000-0000-000000000000", kind, "", now(), 1, new SaveFrequency(0, []), false);
}

export function SimulationModel__ToSimulationInput(x) {
    let matchValue;
    return new SimulationInput(x.aid, x.startTime, (matchValue = x.kind, (matchValue.tag === 5) ? (24 * (x.simDays + 30)) : ((matchValue.tag === 3) ? (24 * (x.simDays + 30)) : (24 * x.simDays))), SaveFrequency__ToFloat(x.saveFreq), x.reverse, "LonLat", 1000000, 10);
}

export class AdvectionInput extends Record {
    constructor(timeStep, integrator, interpolator, landBoundary, openBoundary) {
        super();
        this.timeStep = timeStep;
        this.integrator = integrator;
        this.interpolator = interpolator;
        this.landBoundary = landBoundary;
        this.openBoundary = openBoundary;
    }
}

export function AdvectionInput_$reflection() {
    return record_type("Drifters.ApiTypes.AdvectionInput", [], AdvectionInput, () => [["timeStep", option_type(float64_type)], ["integrator", option_type(string_type)], ["interpolator", option_type(string_type)], ["landBoundary", option_type(string_type)], ["openBoundary", option_type(string_type)]]);
}

export class ReleaseSite extends Record {
    constructor(position, radius, depth, ddepth) {
        super();
        this.position = position;
        this.radius = radius;
        this.depth = depth;
        this.ddepth = ddepth;
    }
}

export function ReleaseSite_$reflection() {
    return record_type("Drifters.ApiTypes.ReleaseSite", [], ReleaseSite, () => [["position", tuple_type(float64_type, float64_type)], ["radius", float64_type], ["depth", float64_type], ["ddepth", float64_type]]);
}

export function ReleaseSite_get_empty() {
    return new ReleaseSite([0, 0], 100, -10, 10);
}

export class GroupInput extends Record {
    constructor(name, sites, distribution) {
        super();
        this.name = name;
        this.sites = sites;
        this.distribution = distribution;
    }
}

export function GroupInput_$reflection() {
    return record_type("Drifters.ApiTypes.GroupInput", [], GroupInput, () => [["name", option_type(string_type)], ["sites", list_type(ReleaseSite_$reflection())], ["distribution", option_type(list_type(tuple_type(int32_type, float64_type)))]]);
}

export function GroupInput_empty_1505(i) {
    return new GroupInput(`Group-${i}`, empty(), undefined);
}

export class GroupModel extends Record {
    constructor(name, sites, distribution) {
        super();
        this.name = name;
        this.sites = sites;
        this.distribution = distribution;
    }
}

export function GroupModel_$reflection() {
    return record_type("Drifters.ApiTypes.GroupModel", [], GroupModel, () => [["name", option_type(string_type)], ["sites", list_type(ReleaseSite_$reflection())], ["distribution", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [ParticleKind_$reflection(), float64_type])]]);
}

export function GroupModel_empty_1505(i) {
    return new GroupModel(`Group-${i}`, empty(), empty_1({
        Compare: compare,
    }));
}

export function GroupModel_lice_1505(i) {
    return new GroupModel(`Group-${i}`, empty(), ofSeq([[new ParticleKind(3, [0]), 1]], {
        Compare: compare,
    }));
}

export function GroupModel_virus_1505(i) {
    return new GroupModel(`Group-${i}`, empty(), ofSeq([[new ParticleKind(2, [0]), 1]], {
        Compare: compare,
    }));
}

export function GroupModel_transport_1505(i) {
    return new GroupModel(`Group-${i}`, empty(), ofSeq([[new ParticleKind(1, [0]), 1]], {
        Compare: compare,
    }));
}

export function GroupModel_deposition_1505(i) {
    return new GroupModel(`Group-${i}`, empty(), ofSeq([[new ParticleKind(4, [0]), 0.662], [new ParticleKind(4, [1]), 0.189], [new ParticleKind(4, [2]), 0.032], [new ParticleKind(4, [3]), 0.026], [new ParticleKind(4, [4]), 0.028], [new ParticleKind(4, [5]), 0.063]], {
        Compare: compare,
    }));
}

export function GroupModel_watercontact_1505(i) {
    return new GroupModel(`Site-${i}`, empty(), ofSeq([[new ParticleKind(1, [0]), 1]], {
        Compare: compare,
    }));
}

export function GroupModel_FromInput(kind, input) {
    return new GroupModel(input.name, input.sites, ofList(defaultArg(map((list) => map_1((tupledArg) => [kind(tupledArg[0]), tupledArg[1]], list), input.distribution), empty()), {
        Compare: compare,
    }));
}

export class ReleaseInput extends Record {
    constructor(particles, span, duration, frequency, groups) {
        super();
        this.particles = (particles | 0);
        this.span = span;
        this.duration = duration;
        this.frequency = frequency;
        this.groups = groups;
    }
}

export function ReleaseInput_$reflection() {
    return record_type("Drifters.ApiTypes.ReleaseInput", [], ReleaseInput, () => [["particles", int32_type], ["span", option_type(float64_type)], ["duration", option_type(float64_type)], ["frequency", option_type(float64_type)], ["groups", list_type(GroupInput_$reflection())]]);
}

export class ReceiveInput extends Record {
    constructor(groups) {
        super();
        this.groups = groups;
    }
}

export function ReceiveInput_$reflection() {
    return record_type("Drifters.ApiTypes.ReceiveInput", [], ReceiveInput, () => [["groups", list_type(GroupInput_$reflection())]]);
}

export class ReleaseModel extends Record {
    constructor(perDay, duration, span, frequency, resolution, groups) {
        super();
        this.perDay = perDay;
        this.duration = duration;
        this.span = span;
        this.frequency = frequency;
        this.resolution = resolution;
        this.groups = groups;
    }
}

export function ReleaseModel_$reflection() {
    return record_type("Drifters.ApiTypes.ReleaseModel", [], ReleaseModel, () => [["perDay", bool_type], ["duration", option_type(float64_type)], ["span", ReleaseFrequency_$reflection()], ["frequency", ReleaseFrequency_$reflection()], ["resolution", ParticleResolution_$reflection()], ["groups", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [int32_type, GroupModel_$reflection()])]]);
}

export function ReleaseModel_get_empty() {
    return new ReleaseModel(false, undefined, new ReleaseFrequency(2, []), new ReleaseFrequency(2, []), new ParticleResolution(1, []), empty_1({
        Compare: comparePrimitives,
    }));
}

export function ReleaseModel_get_lice() {
    return new ReleaseModel(true, undefined, new ReleaseFrequency(2, []), new ReleaseFrequency(2, []), new ParticleResolution(1, []), ofSeq([[0, GroupModel_lice_1505(0)]], {
        Compare: comparePrimitives,
    }));
}

export function ReleaseModel_get_virus() {
    return new ReleaseModel(true, undefined, new ReleaseFrequency(2, []), new ReleaseFrequency(2, []), new ParticleResolution(1, []), ofSeq([[0, GroupModel_virus_1505(0)]], {
        Compare: comparePrimitives,
    }));
}

export function ReleaseModel_get_transport() {
    return new ReleaseModel(false, undefined, new ReleaseFrequency(2, []), new ReleaseFrequency(2, []), new ParticleResolution(1, []), ofSeq([[0, GroupModel_transport_1505(0)]], {
        Compare: comparePrimitives,
    }));
}

export function ReleaseModel_get_deposition() {
    return new ReleaseModel(true, undefined, new ReleaseFrequency(2, []), new ReleaseFrequency(2, []), new ParticleResolution(1, []), ofSeq([[0, GroupModel_deposition_1505(0)]], {
        Compare: comparePrimitives,
    }));
}

export function ReleaseModel_get_watercontact() {
    return new ReleaseModel(true, undefined, new ReleaseFrequency(2, []), new ReleaseFrequency(2, []), new ParticleResolution(1, []), empty_1({
        Compare: comparePrimitives,
    }));
}

export function ReleaseModel__toReleaseInput(x, iotrans, kindToIndex) {
    const patternInput = x.perDay ? [ParticleResolution__ToContinuous(x.resolution), ReleaseFrequency__ToFloat(x.frequency)] : [ParticleResolution__ToSingle(x.resolution), undefined];
    const groups = choose((x_1) => x_1, toList(values(map_2((_arg, group) => {
        if (length(group.sites) > 0) {
            return new GroupInput(group.name, map_1((site) => (new ReleaseSite(iotrans(site.position), site.radius, site.depth, site.ddepth)), group.sites), map_1((tupledArg_1) => [kindToIndex(tupledArg_1[0]), tupledArg_1[1]], filter((tupledArg) => (tupledArg[1] > 0), toList_1(group.distribution))));
        }
        else {
            return undefined;
        }
    }, x.groups))));
    return new ReleaseInput(patternInput[0], 3600 * ReleaseFrequency__ToFloat(x.span), x.duration, patternInput[1], groups);
}

export class ParticleInput extends Record {
    constructor(name, next, mortality, transition, irradiance, salinity, velocity) {
        super();
        this.name = name;
        this.next = next;
        this.mortality = mortality;
        this.transition = transition;
        this.irradiance = irradiance;
        this.salinity = salinity;
        this.velocity = velocity;
    }
}

export function ParticleInput_$reflection() {
    return record_type("Drifters.ApiTypes.ParticleInput", [], ParticleInput, () => [["name", option_type(string_type)], ["next", option_type(int32_type)], ["mortality", option_type(float64_type)], ["transition", option_type(float64_type)], ["irradiance", option_type(float64_type)], ["salinity", option_type(float64_type)], ["velocity", option_type(tuple_type(float64_type, float64_type, float64_type))]]);
}

export function ParticleInput_empty_Z721C83C5(n) {
    return new ParticleInput(n, undefined, undefined, undefined, undefined, undefined, undefined);
}

export function ParticleInput_virus_Z721C83C5(n) {
    return new ParticleInput(n, undefined, 0.21, undefined, undefined, undefined, undefined);
}

export function ParticleInput_watercontact_Z721C83C5(n) {
    return new ParticleInput(n, undefined, undefined, 240, undefined, undefined, undefined);
}

export function ParticleInput_nauplius(n, x) {
    return new ParticleInput(n, x, 0.21, 40, 2E-05, 20, [0, 0, 0.0005]);
}

export function ParticleInput_copepodid_Z721C83C5(n) {
    return new ParticleInput(n, undefined, 0.21, 130, 0.392, 20, [0, 0, 0.0005]);
}

export function ParticleInput_feces(n, vz) {
    return new ParticleInput(n, undefined, undefined, undefined, undefined, undefined, [0, 0, vz]);
}

export class DepositionInput extends Record {
    constructor(resuspension, removeSediment, consolidation, criticalStress, bottomRoughness, minimumDrag, vonKarman) {
        super();
        this.resuspension = resuspension;
        this.removeSediment = removeSediment;
        this.consolidation = consolidation;
        this.criticalStress = criticalStress;
        this.bottomRoughness = bottomRoughness;
        this.minimumDrag = minimumDrag;
        this.vonKarman = vonKarman;
    }
}

export function DepositionInput_$reflection() {
    return record_type("Drifters.ApiTypes.DepositionInput", [], DepositionInput, () => [["resuspension", option_type(bool_type)], ["removeSediment", option_type(bool_type)], ["consolidation", option_type(float64_type)], ["criticalStress", option_type(float64_type)], ["bottomRoughness", option_type(float64_type)], ["minimumDrag", option_type(float64_type)], ["vonKarman", option_type(float64_type)]]);
}

export class DriftersInput extends Record {
    constructor(simulation, advection, release, particles, deposition) {
        super();
        this.simulation = simulation;
        this.advection = advection;
        this.release = release;
        this.particles = particles;
        this.deposition = deposition;
    }
}

export function DriftersInput_$reflection() {
    return record_type("Drifters.ApiTypes.DriftersInput", [], DriftersInput, () => [["simulation", SimulationInput_$reflection()], ["advection", option_type(AdvectionInput_$reflection())], ["release", ReleaseInput_$reflection()], ["particles", option_type(list_type(ParticleInput_$reflection()))], ["deposition", option_type(DepositionInput_$reflection())]]);
}

export class AnalysisInput extends Record {
    constructor(archiveId, startTime, endTime, reverse, saveFreq, projection) {
        super();
        this.archiveId = archiveId;
        this.startTime = startTime;
        this.endTime = endTime;
        this.reverse = reverse;
        this.saveFreq = saveFreq;
        this.projection = projection;
    }
}

export function AnalysisInput_$reflection() {
    return record_type("Drifters.ApiTypes.AnalysisInput", [], AnalysisInput, () => [["archiveId", class_type("System.Guid")], ["startTime", option_type(class_type("System.DateTime"))], ["endTime", option_type(class_type("System.DateTime"))], ["reverse", option_type(bool_type)], ["saveFreq", option_type(float64_type)], ["projection", option_type(string_type)]]);
}

export class AnalysisModel extends Record {
    constructor(aid, kind, name, startTime, endTime, saveFreq, reverse) {
        super();
        this.aid = aid;
        this.kind = kind;
        this.name = name;
        this.startTime = startTime;
        this.endTime = endTime;
        this.saveFreq = saveFreq;
        this.reverse = reverse;
    }
}

export function AnalysisModel_$reflection() {
    return record_type("Drifters.ApiTypes.AnalysisModel", [], AnalysisModel, () => [["aid", class_type("System.Guid")], ["kind", SimType_$reflection()], ["name", string_type], ["startTime", option_type(class_type("System.DateTime"))], ["endTime", option_type(class_type("System.DateTime"))], ["saveFreq", SaveFrequency_$reflection()], ["reverse", bool_type]]);
}

export function AnalysisModel_empty_Z160CC370(kind) {
    return new AnalysisModel("00000000-0000-0000-0000-000000000000", kind, "", undefined, undefined, new SaveFrequency(0, []), false);
}

export function AnalysisModel__toAnalysisInput(x) {
    return new AnalysisInput(x.aid, undefined, undefined, x.reverse, SaveFrequency__ToFloat(x.saveFreq), "LonLat");
}

export class GridInput extends Record {
    constructor(file, scaling, translation) {
        super();
        this.file = file;
        this.scaling = scaling;
        this.translation = translation;
    }
}

export function GridInput_$reflection() {
    return record_type("Drifters.ApiTypes.GridInput", [], GridInput, () => [["file", string_type], ["scaling", option_type(float64_type)], ["translation", option_type(tuple_type(float64_type, float64_type))]]);
}

export class ConcentrationInput extends Record {
    constructor(fields, depths, groups, states, particles, smoothing, normalization) {
        super();
        this.fields = fields;
        this.depths = depths;
        this.groups = groups;
        this.states = states;
        this.particles = particles;
        this.smoothing = smoothing;
        this.normalization = normalization;
    }
}

export function ConcentrationInput_$reflection() {
    return record_type("Drifters.ApiTypes.ConcentrationInput", [], ConcentrationInput, () => [["fields", option_type(list_type(string_type))], ["depths", option_type(list_type(int32_type))], ["groups", option_type(list_type(int32_type))], ["states", option_type(list_type(string_type))], ["particles", option_type(list_type(int32_type))], ["smoothing", option_type(string_type)], ["normalization", option_type(float64_type)]]);
}

export class SedimentationInput extends Record {
    constructor(feedRate, feedFile, field, state, particle, groups, carbon, nitrogen, phosphorus) {
        super();
        this.feedRate = feedRate;
        this.feedFile = feedFile;
        this.field = field;
        this.state = state;
        this.particle = particle;
        this.groups = groups;
        this.carbon = carbon;
        this.nitrogen = nitrogen;
        this.phosphorus = phosphorus;
    }
}

export function SedimentationInput_$reflection() {
    return record_type("Drifters.ApiTypes.SedimentationInput", [], SedimentationInput, () => [["feedRate", option_type(float64_type)], ["feedFile", option_type(string_type)], ["field", option_type(string_type)], ["state", option_type(string_type)], ["particle", option_type(int32_type)], ["groups", option_type(list_type(int32_type))], ["carbon", option_type(list_type(float64_type))], ["nitrogen", option_type(list_type(float64_type))], ["phosphorus", option_type(list_type(float64_type))]]);
}

export class PostdriftInput extends Record {
    constructor(analysis, grid, receive, concentration, sedimentation) {
        super();
        this.analysis = analysis;
        this.grid = grid;
        this.receive = receive;
        this.concentration = concentration;
        this.sedimentation = sedimentation;
    }
}

export function PostdriftInput_$reflection() {
    return record_type("Drifters.ApiTypes.PostdriftInput", [], PostdriftInput, () => [["analysis", AnalysisInput_$reflection()], ["grid", option_type(GridInput_$reflection())], ["receive", option_type(ReceiveInput_$reflection())], ["concentration", option_type(ConcentrationInput_$reflection())], ["sedimentation", option_type(SedimentationInput_$reflection())]]);
}

export class ConcentrationModel extends Record {
    constructor(depths, groups, fields, states, particles, smoothing) {
        super();
        this.depths = depths;
        this.groups = groups;
        this.fields = fields;
        this.states = states;
        this.particles = particles;
        this.smoothing = smoothing;
    }
}

export function ConcentrationModel_$reflection() {
    return record_type("Drifters.ApiTypes.ConcentrationModel", [], ConcentrationModel, () => [["depths", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [DepthKind_$reflection()])], ["groups", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [GroupKind_$reflection()])], ["fields", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [FieldKind_$reflection()])], ["states", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [ParticleState_$reflection()])], ["particles", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [ParticleKind_$reflection()])], ["smoothing", SpatialSmoothing_$reflection()]]);
}

export function ConcentrationModel_get_defaults() {
    const depths = empty_2({
        Compare: compare,
    });
    const fields = ofSeq_1([new FieldKind(0, []), new FieldKind(1, [])], {
        Compare: compare,
    });
    return new ConcentrationModel(depths, empty_2({
        Compare: compare,
    }), fields, empty_2({
        Compare: compare,
    }), empty_2({
        Compare: compare,
    }), new SpatialSmoothing(2, []));
}

export function ConcentrationModel_get_available() {
    const depths = ofSeq_1([new DepthKind(2, [5]), new DepthKind(2, [10]), new DepthKind(2, [25]), new DepthKind(2, [50]), new DepthKind(2, [100])], {
        Compare: compare,
    });
    const fields = ofSeq_1([new FieldKind(0, []), new FieldKind(1, []), new FieldKind(2, []), new FieldKind(3, [new TemporalSmoothing(0, [])]), new FieldKind(3, [new TemporalSmoothing(1, [])]), new FieldKind(3, [new TemporalSmoothing(2, [])]), new FieldKind(3, [new TemporalSmoothing(3, [])]), new FieldKind(3, [new TemporalSmoothing(4, [])])], {
        Compare: compare,
    });
    return new ConcentrationModel(depths, empty_2({
        Compare: compare,
    }), fields, empty_2({
        Compare: compare,
    }), empty_2({
        Compare: compare,
    }), new SpatialSmoothing(2, []));
}

export function ConcentrationModel__toConcentrationInput(x) {
    return new ConcentrationInput(map_1(toString, ofSeq_2(x.fields)), map_1(DepthKind__ToInt, append(singleton(new DepthKind(0, [])), append(ofSeq_2(x.depths), singleton(new DepthKind(1, []))))), mapIndexed((i, _arg_2) => i, ofSeq_2(x.groups)), map_1(toString, ofSeq_2(x.states)), map_1(ParticleKind__ToInt, ofSeq_2(x.particles)), toString(x.smoothing), 1);
}

export class SedimentationModel extends Record {
    constructor(resuspension, consolidation, criticalStress) {
        super();
        this.resuspension = resuspension;
        this.consolidation = consolidation;
        this.criticalStress = criticalStress;
    }
}

export function SedimentationModel_$reflection() {
    return record_type("Drifters.ApiTypes.SedimentationModel", [], SedimentationModel, () => [["resuspension", bool_type], ["consolidation", float64_type], ["criticalStress", ShearValue_$reflection()]]);
}

export function SedimentationModel_get_defaults() {
    return new SedimentationModel(false, 24, new ShearValue(0, []));
}

export function SedimentationModel__toDepositionInput(x) {
    return new DepositionInput(x.resuspension, undefined, x.resuspension ? x.consolidation : 1, ShearValue__ToFloat(x.criticalStress), undefined, undefined, undefined);
}

