import { Remoting_buildProxy_64DC51C } from "../fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { RemotingModule_withBinarySerialization, RemotingModule_withBaseUrl, RemotingModule_createApi, RemotingModule_withCredentials, RemotingModule_withRouteBuilder } from "../fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { Drifters_$reflection, Inbox_$reflection, authorizedRouteBuilder, Services_$reflection, User_$reflection, Auth_$reflection, apiRouteBuilder } from "../Interfaces/Atlantis/Api.jsx";
import { Archive_$reflection, ModelArea_$reflection, apiRouteBuilder as apiRouteBuilder_1, Inventory_$reflection, authorizedRouteBuilder as authorizedRouteBuilder_1 } from "../fable_modules/Oceanbox.DataAgent.Api.6.24.0/Api.fs.js";
import { class_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { Crop_Stats_$reflection, Crop_Fvcom_$reflection, Crop_routeBuilder, Proximity_Prox_$reflection, Proximity_routeBuilder, Stats_routeBuilder, Stats_FvStatsSeries_$reflection, Stats_FvStatsByIndex_$reflection, Stats_FvStatsByLayer_$reflection, Stats_FvStatsInfo_$reflection, Atmo_routeBuilder, Atmo_Wind_$reflection, Drifters_routeBuilder, Drifters_Network_$reflection, Drifters_Field3D_$reflection, Drifters_Field2D_$reflection, Drifters_Sedimentation_$reflection, Drifters_FieldMetaData_$reflection, Drifters_Particles_$reflection, Drifters_Archive_$reflection, Fvcom_routeBuilder, Fvcom_TimeSeries_$reflection, Fvcom_Batch_$reflection, Fvcom_Element_$reflection, Fvcom_Node_$reflection, Fvcom_Layer_$reflection, Fvcom_Archive_$reflection } from "../fable_modules/Sorcerer.Api.4.14.0/Api.fs.js";
import { uncurry2 } from "../fable_modules/fable-library-js.4.24.0/Util.js";

export const authApi = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(apiRouteBuilder, RemotingModule_withCredentials(true, RemotingModule_createApi())), Auth_$reflection());

export const userApi = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(apiRouteBuilder, RemotingModule_withCredentials(true, RemotingModule_createApi())), User_$reflection());

export const servicesApi = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(apiRouteBuilder, RemotingModule_withCredentials(true, RemotingModule_createApi())), Services_$reflection());

export function inboxApi() {
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(authorizedRouteBuilder, RemotingModule_withCredentials(true, RemotingModule_createApi())), Inbox_$reflection());
}

export class ArchivesApi {
    constructor(serverUrl) {
        this["Archive@"] = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(authorizedRouteBuilder_1, RemotingModule_withBaseUrl(serverUrl, RemotingModule_withCredentials(true, RemotingModule_createApi()))), Inventory_$reflection());
        this["ModelArea@"] = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(apiRouteBuilder_1, RemotingModule_withBaseUrl(serverUrl, RemotingModule_withCredentials(true, RemotingModule_createApi()))), ModelArea_$reflection());
    }
}

export function ArchivesApi_$reflection() {
    return class_type("Remoting.ArchivesApi", undefined, ArchivesApi);
}

export function ArchivesApi_$ctor_Z721C83C5(serverUrl) {
    return new ArchivesApi(serverUrl);
}

export function ArchivesApi__get_Archive(__) {
    return __["Archive@"];
}

export function ArchivesApi__get_ModelArea(__) {
    return __["ModelArea@"];
}

export class FvcomApi {
    constructor(url) {
        this.url = url;
        this["Archive@"] = FvcomApi__createApi_291EB319(this, (options) => Remoting_buildProxy_64DC51C(options, Fvcom_Archive_$reflection()));
        this["Layer@"] = FvcomApi__createBinApi_291EB319(this, (options_2) => Remoting_buildProxy_64DC51C(options_2, Fvcom_Layer_$reflection()));
        this["Node@"] = FvcomApi__createBinApi_291EB319(this, (options_4) => Remoting_buildProxy_64DC51C(options_4, Fvcom_Node_$reflection()));
        this["Element@"] = FvcomApi__createBinApi_291EB319(this, (options_6) => Remoting_buildProxy_64DC51C(options_6, Fvcom_Element_$reflection()));
        this["Batch@"] = FvcomApi__createBinApi_291EB319(this, (options_8) => Remoting_buildProxy_64DC51C(options_8, Fvcom_Batch_$reflection()));
        this["TimeSeries@"] = FvcomApi__createBinApi_291EB319(this, (options_10) => Remoting_buildProxy_64DC51C(options_10, Fvcom_TimeSeries_$reflection()));
    }
}

export function FvcomApi_$reflection() {
    return class_type("Remoting.FvcomApi", undefined, FvcomApi);
}

export function FvcomApi_$ctor_Z721C83C5(url) {
    return new FvcomApi(url);
}

export function FvcomApi__get_Archive(__) {
    return __["Archive@"];
}

export function FvcomApi__get_Layer(__) {
    return __["Layer@"];
}

export function FvcomApi__get_Node(__) {
    return __["Node@"];
}

export function FvcomApi__get_Element(__) {
    return __["Element@"];
}

export function FvcomApi__get_Batch(__) {
    return __["Batch@"];
}

export function FvcomApi__get_TimeSeries(__) {
    return __["TimeSeries@"];
}

export function FvcomApi__createBinApi_291EB319(this$, f) {
    return f(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(uncurry2(Fvcom_routeBuilder), RemotingModule_withBaseUrl(this$.url, RemotingModule_withCredentials(true, RemotingModule_createApi())))));
}

export function FvcomApi__createApi_291EB319(this$, f) {
    return f(RemotingModule_withRouteBuilder(uncurry2(Fvcom_routeBuilder), RemotingModule_withBaseUrl(this$.url, RemotingModule_withCredentials(true, RemotingModule_createApi()))));
}

export class DriftersApi {
    constructor(url) {
        this.url = url;
        this["DriftersArchive@"] = DriftersApi__createApi_291EB319(this, (options) => Remoting_buildProxy_64DC51C(options, Drifters_Archive_$reflection()));
        this["Particles@"] = DriftersApi__createBinApi_291EB319(this, (options_2) => Remoting_buildProxy_64DC51C(options_2, Drifters_Particles_$reflection()));
        this["FieldMetaData@"] = DriftersApi__createBinApi_291EB319(this, (options_4) => Remoting_buildProxy_64DC51C(options_4, Drifters_FieldMetaData_$reflection()));
        this["Sedimentation@"] = DriftersApi__createBinApi_291EB319(this, (options_6) => Remoting_buildProxy_64DC51C(options_6, Drifters_Sedimentation_$reflection()));
        this["Field2D@"] = DriftersApi__createBinApi_291EB319(this, (options_8) => Remoting_buildProxy_64DC51C(options_8, Drifters_Field2D_$reflection()));
        this["Field3D@"] = DriftersApi__createBinApi_291EB319(this, (options_10) => Remoting_buildProxy_64DC51C(options_10, Drifters_Field3D_$reflection()));
        this["Network@"] = DriftersApi__createApi_291EB319(this, (options_12) => Remoting_buildProxy_64DC51C(options_12, Drifters_Network_$reflection()));
    }
}

export function DriftersApi_$reflection() {
    return class_type("Remoting.DriftersApi", undefined, DriftersApi);
}

export function DriftersApi_$ctor_Z721C83C5(url) {
    return new DriftersApi(url);
}

export function DriftersApi__get_DriftersArchive(__) {
    return __["DriftersArchive@"];
}

export function DriftersApi__get_Particles(__) {
    return __["Particles@"];
}

export function DriftersApi__get_FieldMetaData(__) {
    return __["FieldMetaData@"];
}

export function DriftersApi__get_Sedimentation(__) {
    return __["Sedimentation@"];
}

export function DriftersApi__get_Field2D(__) {
    return __["Field2D@"];
}

export function DriftersApi__get_Field3D(__) {
    return __["Field3D@"];
}

export function DriftersApi__get_Network(__) {
    return __["Network@"];
}

export function DriftersApi__createBinApi_291EB319(this$, f) {
    return f(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(uncurry2(Drifters_routeBuilder), RemotingModule_withBaseUrl(this$.url, RemotingModule_withCredentials(true, RemotingModule_createApi())))));
}

export function DriftersApi__createApi_291EB319(this$, f) {
    return f(RemotingModule_withRouteBuilder(uncurry2(Drifters_routeBuilder), RemotingModule_withBaseUrl(this$.url, RemotingModule_withCredentials(true, RemotingModule_createApi()))));
}

export class AtmoApi {
    constructor(url) {
        this.url = url;
        this["Wind@"] = AtmoApi__createApi_291EB319(this, (options) => Remoting_buildProxy_64DC51C(options, Atmo_Wind_$reflection()));
    }
}

export function AtmoApi_$reflection() {
    return class_type("Remoting.AtmoApi", undefined, AtmoApi);
}

export function AtmoApi_$ctor_Z721C83C5(url) {
    return new AtmoApi(url);
}

export function AtmoApi__get_Wind(__) {
    return __["Wind@"];
}

export function AtmoApi__createApi_291EB319(this$, f) {
    return f(RemotingModule_withRouteBuilder(uncurry2(Atmo_routeBuilder), RemotingModule_withBaseUrl(this$.url, RemotingModule_withCredentials(true, RemotingModule_createApi()))));
}

export class StatsApi {
    constructor(url) {
        this.url = url;
        this["FvStatsInfo@"] = StatsApi__createApi_291EB319(this, (options) => Remoting_buildProxy_64DC51C(options, Stats_FvStatsInfo_$reflection()));
        this["FvStatsByLayer@"] = StatsApi__createApi_291EB319(this, (arg) => Remoting_buildProxy_64DC51C(RemotingModule_withBinarySerialization(arg), Stats_FvStatsByLayer_$reflection()));
        this["FvStatsByIndex@"] = StatsApi__createApi_291EB319(this, (arg_1) => Remoting_buildProxy_64DC51C(RemotingModule_withBinarySerialization(arg_1), Stats_FvStatsByIndex_$reflection()));
        this["FvStatsSeries@"] = StatsApi__createApi_291EB319(this, (arg_2) => Remoting_buildProxy_64DC51C(RemotingModule_withBinarySerialization(arg_2), Stats_FvStatsSeries_$reflection()));
    }
}

export function StatsApi_$reflection() {
    return class_type("Remoting.StatsApi", undefined, StatsApi);
}

export function StatsApi_$ctor_Z721C83C5(url) {
    return new StatsApi(url);
}

export function StatsApi__get_FvStatsInfo(__) {
    return __["FvStatsInfo@"];
}

export function StatsApi__get_FvStatsByLayer(__) {
    return __["FvStatsByLayer@"];
}

export function StatsApi__get_FvStatsByIndex(__) {
    return __["FvStatsByIndex@"];
}

export function StatsApi__get_FvStatsSeries(__) {
    return __["FvStatsSeries@"];
}

export function StatsApi__createApi_291EB319(this$, f) {
    return f(RemotingModule_withRouteBuilder(uncurry2(Stats_routeBuilder), RemotingModule_withBaseUrl(this$.url, RemotingModule_withCredentials(true, RemotingModule_createApi()))));
}

export function driftersJobApi() {
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(authorizedRouteBuilder, RemotingModule_withCredentials(true, RemotingModule_createApi())), Drifters_$reflection());
}

export const archiveUrl = sessionStorage["archmaester_url"];

export function archiveApi() {
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(apiRouteBuilder_1, RemotingModule_withCredentials(true, RemotingModule_createApi())), Archive_$reflection());
}

export function inventoryApi() {
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(authorizedRouteBuilder_1, RemotingModule_withCredentials(true, RemotingModule_createApi())), Inventory_$reflection());
}

export function proximityApi(url) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(uncurry2(Proximity_routeBuilder), RemotingModule_withBaseUrl(url, RemotingModule_withCredentials(true, RemotingModule_createApi()))), Proximity_Prox_$reflection());
}

export function cropApi(url) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(uncurry2(Crop_routeBuilder), RemotingModule_withBaseUrl(url, RemotingModule_withCredentials(true, RemotingModule_createApi())))), Crop_Fvcom_$reflection());
}

export function cropStatsApi(url) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(uncurry2(Crop_routeBuilder), RemotingModule_withBaseUrl(url, RemotingModule_withCredentials(true, RemotingModule_createApi())))), Crop_Stats_$reflection());
}

