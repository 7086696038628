import { toString, Record, Union } from "../fable-library-js.4.24.0/Types.js";
import { record_type, array_type, bool_type, int32_type, tuple_type, float32_type, string_type, option_type, class_type, union_type } from "../fable-library-js.4.24.0/Reflection.js";
import { split } from "../fable-library-js.4.24.0/String.js";
import { item } from "../fable-library-js.4.24.0/Array.js";
import { map, bind } from "../fable-library-js.4.24.0/Option.js";
import { utcNow } from "../fable-library-js.4.24.0/Date.js";

export class FvcomVariant extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Any"];
    }
    toString() {
        return "*";
    }
}

export function FvcomVariant_$reflection() {
    return union_type("Archmaester.Types.FvcomVariant", [], FvcomVariant, () => [[]]);
}

export class FvcomFormat extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Any"];
    }
    toString() {
        return "*";
    }
}

export function FvcomFormat_$reflection() {
    return union_type("Archmaester.Types.FvcomFormat", [], FvcomFormat, () => [[]]);
}

export class FvStatsVariant extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Temp", "Salt", "UV", "Any"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "salt" : ((x.tag === 2) ? "uv" : ((x.tag === 3) ? "*" : "temp"));
    }
}

export function FvStatsVariant_$reflection() {
    return union_type("Archmaester.Types.FvStatsVariant", [], FvStatsVariant, () => [[], [], [], []]);
}

export class FvStatsFormat extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Any", "Count"];
    }
    toString() {
        const x = this;
        return (x.tag === 0) ? "*" : "count";
    }
}

export function FvStatsFormat_$reflection() {
    return union_type("Archmaester.Types.FvStatsFormat", [], FvStatsFormat, () => [[], []]);
}

export class DriftersVariant extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Lice", "Virus", "Transport", "Sedimentation", "Accumulation", "WaterContact", "Downwelling", "Any"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "virus" : ((x.tag === 2) ? "transport" : ((x.tag === 3) ? "sedimentation" : ((x.tag === 4) ? "accumulation" : ((x.tag === 5) ? "watercontact" : ((x.tag === 6) ? "downwelling" : ((x.tag === 7) ? "*" : "lice"))))));
    }
}

export function DriftersVariant_$reflection() {
    return union_type("Archmaester.Types.DriftersVariant", [], DriftersVariant, () => [[], [], [], [], [], [], [], []]);
}

export class DriftersFormat extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Particle", "Field2D", "Field3D", "Any"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "field2d" : ((x.tag === 2) ? "field3d" : ((x.tag === 3) ? "*" : "particle"));
    }
}

export function DriftersFormat_$reflection() {
    return union_type("Archmaester.Types.DriftersFormat", [], DriftersFormat, () => [[], [], [], []]);
}

export class AtmoVariant extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Arome", "Any"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "*" : "arome";
    }
}

export function AtmoVariant_$reflection() {
    return union_type("Archmaester.Types.AtmoVariant", [], AtmoVariant, () => [[], []]);
}

export class AtmoFormat extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["MetCoop", "Any"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "*" : "metcoop";
    }
}

export function AtmoFormat_$reflection() {
    return union_type("Archmaester.Types.AtmoFormat", [], AtmoFormat, () => [[], []]);
}

export class ArchiveType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Fvcom", "Drifters", "Atmo", "FvStats", "Any"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? (`drifters:${x.fields[0]}:${x.fields[1]}`) : ((x.tag === 2) ? (`atmo:${x.fields[0]}:${x.fields[1]}`) : ((x.tag === 3) ? (`fvstats:${x.fields[0]}:${x.fields[1]}`) : ((x.tag === 4) ? "*:*:*" : (`fvcom:${x.fields[0]}:${x.fields[1]}`))));
    }
}

export function ArchiveType_$reflection() {
    return union_type("Archmaester.Types.ArchiveType", [], ArchiveType, () => [[["Item1", FvcomVariant_$reflection()], ["Item2", FvcomFormat_$reflection()]], [["Item1", DriftersVariant_$reflection()], ["Item2", DriftersFormat_$reflection()]], [["Item1", AtmoVariant_$reflection()], ["Item2", AtmoFormat_$reflection()]], [["Item1", FvStatsVariant_$reflection()], ["Item2", FvStatsFormat_$reflection()]], []]);
}

export class AclType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Owner", "User", "Group"];
    }
}

export function AclType_$reflection() {
    return union_type("Archmaester.Types.AclType", [], AclType, () => [[], [], []]);
}

export class ArchiveProps extends Record {
    constructor(archiveId, reference, modelArea, name, description, archiveType, projection, focalPoint, defaultZoom, freq, frames, startTime, endTime, created, owner, expires, isPublished, isPublic, polygon, json) {
        super();
        this.archiveId = archiveId;
        this.reference = reference;
        this.modelArea = modelArea;
        this.name = name;
        this.description = description;
        this.archiveType = archiveType;
        this.projection = projection;
        this.focalPoint = focalPoint;
        this.defaultZoom = defaultZoom;
        this.freq = (freq | 0);
        this.frames = (frames | 0);
        this.startTime = startTime;
        this.endTime = endTime;
        this.created = created;
        this.owner = owner;
        this.expires = expires;
        this.isPublished = isPublished;
        this.isPublic = isPublic;
        this.polygon = polygon;
        this.json = json;
    }
}

export function ArchiveProps_$reflection() {
    return record_type("Archmaester.Types.ArchiveProps", [], ArchiveProps, () => [["archiveId", class_type("System.Guid")], ["reference", option_type(class_type("System.Guid"))], ["modelArea", class_type("System.Guid")], ["name", string_type], ["description", string_type], ["archiveType", ArchiveType_$reflection()], ["projection", string_type], ["focalPoint", tuple_type(float32_type, float32_type)], ["defaultZoom", float32_type], ["freq", int32_type], ["frames", int32_type], ["startTime", class_type("System.DateTime")], ["endTime", class_type("System.DateTime")], ["created", class_type("System.DateTime")], ["owner", string_type], ["expires", option_type(class_type("System.DateTime"))], ["isPublished", bool_type], ["isPublic", bool_type], ["polygon", array_type(tuple_type(float32_type, float32_type))], ["json", string_type]]);
}

export class ArchiveAcl extends Record {
    constructor(owners, users, groups, shares) {
        super();
        this.owners = owners;
        this.users = users;
        this.groups = groups;
        this.shares = shares;
    }
}

export function ArchiveAcl_$reflection() {
    return record_type("Archmaester.Types.ArchiveAcl", [], ArchiveAcl, () => [["owners", array_type(string_type)], ["users", array_type(string_type)], ["groups", array_type(string_type)], ["shares", array_type(class_type("System.Guid"))]]);
}

export class ArchiveFile extends Record {
    constructor(name, frames, ordering, startTime, endTime, reverse) {
        super();
        this.name = name;
        this.frames = (frames | 0);
        this.ordering = (ordering | 0);
        this.startTime = startTime;
        this.endTime = endTime;
        this.reverse = reverse;
    }
}

export function ArchiveFile_$reflection() {
    return record_type("Archmaester.Types.ArchiveFile", [], ArchiveFile, () => [["name", string_type], ["frames", int32_type], ["ordering", int32_type], ["startTime", class_type("System.DateTime")], ["endTime", class_type("System.DateTime")], ["reverse", bool_type]]);
}

export class ArchiveFiles extends Record {
    constructor(basePath, series) {
        super();
        this.basePath = basePath;
        this.series = series;
    }
}

export function ArchiveFiles_$reflection() {
    return record_type("Archmaester.Types.ArchiveFiles", [], ArchiveFiles, () => [["basePath", string_type], ["series", array_type(ArchiveFile_$reflection())]]);
}

export class ArchiveService extends Record {
    constructor(url, credentials) {
        super();
        this.url = url;
        this.credentials = credentials;
    }
}

export function ArchiveService_$reflection() {
    return record_type("Archmaester.Types.ArchiveService", [], ArchiveService, () => [["url", string_type], ["credentials", string_type]]);
}

export class ArchiveDto extends Record {
    constructor(props, polygon, files, acl, retired, json) {
        super();
        this.props = props;
        this.polygon = polygon;
        this.files = files;
        this.acl = acl;
        this.retired = retired;
        this.json = json;
    }
}

export function ArchiveDto_$reflection() {
    return record_type("Archmaester.Types.ArchiveDto", [], ArchiveDto, () => [["props", ArchiveProps_$reflection()], ["polygon", array_type(tuple_type(float32_type, float32_type))], ["files", ArchiveFiles_$reflection()], ["acl", ArchiveAcl_$reflection()], ["retired", bool_type], ["json", string_type]]);
}

export class ModelArea extends Record {
    constructor(modelAreaId, name, description, archives, models, parent, focalPoint, defaultZoom, polygon, json) {
        super();
        this.modelAreaId = modelAreaId;
        this.name = name;
        this.description = description;
        this.archives = archives;
        this.models = models;
        this.parent = parent;
        this.focalPoint = focalPoint;
        this.defaultZoom = defaultZoom;
        this.polygon = polygon;
        this.json = json;
    }
}

export function ModelArea_$reflection() {
    return record_type("Archmaester.Types.ModelArea", [], ModelArea, () => [["modelAreaId", class_type("System.Guid")], ["name", string_type], ["description", string_type], ["archives", array_type(class_type("System.Guid"))], ["models", array_type(class_type("System.Guid"))], ["parent", option_type(class_type("System.Guid"))], ["focalPoint", tuple_type(float32_type, float32_type)], ["defaultZoom", float32_type], ["polygon", array_type(tuple_type(float32_type, float32_type))], ["json", string_type]]);
}

export class ArchiveFilter extends Record {
    constructor(archiveType, owner, user, groups) {
        super();
        this.archiveType = archiveType;
        this.owner = owner;
        this.user = user;
        this.groups = groups;
    }
}

export function ArchiveFilter_$reflection() {
    return record_type("Archmaester.Types.ArchiveFilter", [], ArchiveFilter, () => [["archiveType", option_type(ArchiveType_$reflection())], ["owner", option_type(string_type)], ["user", option_type(string_type)], ["groups", option_type(array_type(string_type))]]);
}

export class SubArchiveDef extends Record {
    constructor(uuid, reference, name, startFile, endFile, acl, polygon, json, isPublished, isPublic) {
        super();
        this.uuid = uuid;
        this.reference = reference;
        this.name = name;
        this.startFile = (startFile | 0);
        this.endFile = (endFile | 0);
        this.acl = acl;
        this.polygon = polygon;
        this.json = json;
        this.isPublished = isPublished;
        this.isPublic = isPublic;
    }
}

export function SubArchiveDef_$reflection() {
    return record_type("Archmaester.Types.SubArchiveDef", [], SubArchiveDef, () => [["uuid", class_type("System.Guid")], ["reference", class_type("System.Guid")], ["name", string_type], ["startFile", int32_type], ["endFile", int32_type], ["acl", ArchiveAcl_$reflection()], ["polygon", array_type(tuple_type(float32_type, float32_type))], ["json", string_type], ["isPublished", bool_type], ["isPublic", bool_type]]);
}

export function FvcomVariant_FromString_Z721C83C5(x) {
    const matchValue = x.toLocaleLowerCase();
    return new FvcomVariant();
}

export function FvcomVariant_Label_6F050E69(x) {
    return "*";
}

export function FvcomVariant__Label(x) {
    return FvcomVariant_Label_6F050E69(x);
}

export function FvcomFormat_FromString_Z721C83C5(x) {
    const matchValue = x.toLocaleLowerCase();
    return new FvcomFormat();
}

export function FvcomFormat_Label_28BE4DFD(x) {
    return "*";
}

export function FvcomFormat__Label(x) {
    return FvcomFormat_Label_28BE4DFD(x);
}

export function FvStatsVariant_FromString_Z721C83C5(x) {
    const matchValue = x.toLocaleLowerCase();
    switch (matchValue) {
        case "temp":
            return new FvStatsVariant(0, []);
        case "salt":
            return new FvStatsVariant(1, []);
        case "uv":
            return new FvStatsVariant(2, []);
        default:
            return new FvStatsVariant(3, []);
    }
}

export function FvStatsVariant_Label_6B1CB969(x) {
    switch (x.tag) {
        case 1:
            return "Salt";
        case 2:
            return "UV";
        case 3:
            return "*";
        default:
            return "Temp";
    }
}

export function FvStatsVariant__Label(x) {
    return FvStatsVariant_Label_6B1CB969(x);
}

export function FvStatsFormat_FromString_Z721C83C5(x) {
    if (x.toLocaleLowerCase() === "count") {
        return new FvStatsFormat(1, []);
    }
    else {
        return new FvStatsFormat(0, []);
    }
}

export function FvStatsFormat_Label_Z24F36703(x) {
    if (x.tag === 0) {
        return "*";
    }
    else {
        return "Count";
    }
}

export function FvStatsFormat__Label(x) {
    return FvStatsFormat_Label_Z24F36703(x);
}

export function DriftersVariant_FromString_Z721C83C5(x) {
    const matchValue = x.toLocaleLowerCase();
    switch (matchValue) {
        case "lice":
            return new DriftersVariant(0, []);
        case "virus":
            return new DriftersVariant(1, []);
        case "transport":
            return new DriftersVariant(2, []);
        case "watercontact":
            return new DriftersVariant(5, []);
        case "sedimentation":
            return new DriftersVariant(3, []);
        case "accumulation":
            return new DriftersVariant(4, []);
        case "downwelling":
            return new DriftersVariant(6, []);
        default:
            return new DriftersVariant(7, []);
    }
}

export function DriftersVariant_Label_4ADC4871(x) {
    switch (x.tag) {
        case 1:
            return "Virus";
        case 2:
            return "Transport";
        case 3:
            return "Sedimentation";
        case 4:
            return "Accumulation";
        case 5:
            return "WaterContact";
        case 6:
            return "Downwelling";
        case 7:
            return "*";
        default:
            return "Lice";
    }
}

export function DriftersVariant__Label(x) {
    return DriftersVariant_Label_4ADC4871(x);
}

export function DriftersFormat_FromString_Z721C83C5(x) {
    const matchValue = x.toLocaleLowerCase();
    switch (matchValue) {
        case "particle":
            return new DriftersFormat(0, []);
        case "field2d":
            return new DriftersFormat(1, []);
        case "field3d":
            return new DriftersFormat(2, []);
        default:
            return new DriftersFormat(3, []);
    }
}

export function DriftersFormat_Label_Z313F111B(x) {
    switch (x.tag) {
        case 1:
            return "Field2D";
        case 2:
            return "Field3D";
        case 3:
            return "*";
        default:
            return "Particle";
    }
}

export function DriftersFormat__Label(x) {
    return DriftersFormat_Label_Z313F111B(x);
}

export function AtmoVariant_FromString_Z721C83C5(x) {
    if (x.toLocaleLowerCase() === "arome") {
        return new AtmoVariant(1, []);
    }
    else {
        return new AtmoVariant(1, []);
    }
}

export function AtmoVariant_Label_7C4EC24F(x) {
    if (x.tag === 1) {
        return "*";
    }
    else {
        return "AROME";
    }
}

export function AtmoVariant__Label(x) {
    return AtmoVariant_Label_7C4EC24F(x);
}

export function AtmoFormat_FromString_Z721C83C5(x) {
    if (x.toLocaleLowerCase() === "metcoop") {
        return new AtmoFormat(0, []);
    }
    else {
        return new AtmoFormat(1, []);
    }
}

export function AtmoFormat_Label_5E11F45B(x) {
    if (x.tag === 1) {
        return "*";
    }
    else {
        return "MetCoop";
    }
}

export function AtmoFormat__Label(x) {
    return AtmoFormat_Label_5E11F45B(x);
}

export function $007CDriftersSpec$007C_$007C(str) {
    const kvf = split(str, [":"], undefined, 0);
    if ((kvf.length === 3) && (item(0, kvf) === "drifters")) {
        let v;
        const matchValue = item(1, kvf).toLocaleLowerCase();
        v = ((matchValue === "lice") ? (new DriftersVariant(0, [])) : ((matchValue === "virus") ? (new DriftersVariant(1, [])) : ((matchValue === "transport") ? (new DriftersVariant(2, [])) : ((matchValue === "sedimentation") ? (new DriftersVariant(3, [])) : ((matchValue === "accumulation") ? (new DriftersVariant(4, [])) : ((matchValue === "watercontact") ? (new DriftersVariant(5, [])) : ((matchValue === "downwelling") ? (new DriftersVariant(6, [])) : undefined)))))));
        let f;
        const matchValue_1 = item(2, kvf).toLocaleLowerCase();
        f = ((matchValue_1 === "particle") ? (new DriftersFormat(0, [])) : ((matchValue_1 === "field2d") ? (new DriftersFormat(1, [])) : ((matchValue_1 === "field3d") ? (new DriftersFormat(2, [])) : ((matchValue_1 === "*") ? (new DriftersFormat(3, [])) : undefined))));
        return bind((v_1) => map((f_1) => [v_1, f_1], f), v);
    }
    else {
        return undefined;
    }
}

export function $007CFvcomSpec$007C_$007C(str) {
    const kvf = split(str, [":"], undefined, 0);
    if ((kvf.length === 3) && (item(0, kvf) === "fvcom")) {
        const v = (item(1, kvf).toLocaleLowerCase() === "*") ? (new FvcomVariant()) : undefined;
        const f = (item(2, kvf).toLocaleLowerCase() === "*") ? (new FvcomFormat()) : undefined;
        return bind((v_1) => map((f_1) => [v_1, f_1], f), v);
    }
    else {
        return undefined;
    }
}

export function $007CAtmoSpec$007C_$007C(str) {
    const kvf = split(str, [":"], undefined, 0);
    if ((kvf.length === 3) && (item(0, kvf) === "atmo")) {
        let v;
        const matchValue = item(1, kvf).toLocaleLowerCase();
        v = ((matchValue === "arome") ? (new AtmoVariant(0, [])) : ((matchValue === "*") ? (new AtmoVariant(1, [])) : undefined));
        let f;
        const matchValue_1 = item(2, kvf).toLocaleLowerCase();
        f = ((matchValue_1 === "metcoop") ? (new AtmoFormat(0, [])) : ((matchValue_1 === "*") ? (new AtmoFormat(1, [])) : undefined));
        return bind((v_1) => map((f_1) => [v_1, f_1], f), v);
    }
    else {
        return undefined;
    }
}

export function $007CFvStatsSpec$007C_$007C(str) {
    const kvf = split(str, [":"], undefined, 0);
    if ((kvf.length === 3) && (item(0, kvf) === "fvstats")) {
        let v;
        const matchValue = item(1, kvf).toLocaleLowerCase();
        v = ((matchValue === "*") ? (new FvStatsVariant(3, [])) : ((matchValue === "uv") ? (new FvStatsVariant(2, [])) : ((matchValue === "temp") ? (new FvStatsVariant(0, [])) : ((matchValue === "salt") ? (new FvStatsVariant(1, [])) : undefined))));
        let f;
        const matchValue_1 = item(2, kvf).toLocaleLowerCase();
        f = ((matchValue_1 === "count") ? (new FvStatsFormat(1, [])) : ((matchValue_1 === "*") ? (new FvStatsFormat(0, [])) : undefined));
        return bind((v_1) => map((f_1) => [v_1, f_1], f), v);
    }
    else {
        return undefined;
    }
}

export function ArchiveType_FromString_Z721C83C5(x) {
    const matchValue = x.toLocaleLowerCase();
    const activePatternResult = $007CFvcomSpec$007C_$007C(matchValue);
    if (activePatternResult != null) {
        const x_1 = activePatternResult;
        const tupledArg = x_1;
        return new ArchiveType(0, [tupledArg[0], tupledArg[1]]);
    }
    else {
        const activePatternResult_1 = $007CDriftersSpec$007C_$007C(matchValue);
        if (activePatternResult_1 != null) {
            const x_2 = activePatternResult_1;
            const tupledArg_1 = x_2;
            return new ArchiveType(1, [tupledArg_1[0], tupledArg_1[1]]);
        }
        else {
            const activePatternResult_2 = $007CAtmoSpec$007C_$007C(matchValue);
            if (activePatternResult_2 != null) {
                const x_3 = activePatternResult_2;
                const tupledArg_2 = x_3;
                return new ArchiveType(2, [tupledArg_2[0], tupledArg_2[1]]);
            }
            else {
                const activePatternResult_3 = $007CFvStatsSpec$007C_$007C(matchValue);
                if (activePatternResult_3 != null) {
                    const x_4 = activePatternResult_3;
                    const tupledArg_3 = x_4;
                    return new ArchiveType(3, [tupledArg_3[0], tupledArg_3[1]]);
                }
                else {
                    return new ArchiveType(4, []);
                }
            }
        }
    }
}

export function ArchiveType_FromDbType_56A1A5(kind, variant, format) {
    return ArchiveType_FromString_Z721C83C5(`${kind}:${variant}:${format}`);
}

export function ArchiveType__ToDbType(x) {
    const y = split(toString(x), [":"], undefined, 0);
    return [item(0, y), item(1, y), item(2, y)];
}

export const HelloWorld = "00000000-0000-0000-0000-000000000000";

export function ArchiveProps_get_empty() {
    return new ArchiveProps("00000000-0000-0000-0000-000000000000", undefined, HelloWorld, "", "", new ArchiveType(0, [new FvcomVariant(), new FvcomFormat()]), "", [0, 0], 0, 0, 0, utcNow(), utcNow(), utcNow(), "", undefined, false, false, [], "");
}

export function ArchiveAcl_get_empty() {
    return new ArchiveAcl([], [], [], []);
}

export function ArchiveFiles_get_empty() {
    return new ArchiveFiles("", []);
}

export function ArchiveDto_get_empty() {
    return new ArchiveDto(ArchiveProps_get_empty(), [], ArchiveFiles_get_empty(), ArchiveAcl_get_empty(), false, "");
}

export function ModelArea_get_empty() {
    return new ModelArea("00000000-0000-0000-0000-000000000000", "", "", [], [], undefined, [0, 0], 9, [], "");
}

export function ArchiveFilter_get_empty() {
    return new ArchiveFilter(undefined, undefined, undefined, undefined);
}

export function SubArchiveDef_get_empty() {
    return new SubArchiveDef("00000000-0000-0000-0000-000000000000", "00000000-0000-0000-0000-000000000000", "", 0, 2147483647, ArchiveAcl_get_empty(), [], "", false, true);
}

